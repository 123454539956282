<template>
	<div class="login_page">
		<loginRegistration pageType="login">
			<div class="flex">
				<div class="slogan_tit_1">密码登录</div>
			</div>
			<div class="login_form">
				<a-input placeholder="请输入手机号码" type="number" v-model="phone" />
				<a-input-password placeholder="请输入密码（6位数字）" v-model="password" />
				<div class="flex margin_b_26">
					<a-input placeholder="请输入验证码" v-model="captcha" />
					<div class="code" @click="refreshCaptcha">
						<img :src="captchaImg" alt="图像验证码" class="captcha_img" v-if="captchaImg" />
					</div>
				</div>
				<a-button class="submit_btn" @click="goLogin" :loading="logLoading">密码登录</a-button>
				<!-- <div class="submit_qywx_btn" @click="goQwLogin">
          <img src="../../assets/qywx_logo.png" alt="" class="qywx_logo" />
          <span>企业微信登录</span>
        </div> -->
			</div>
			<a-checkbox v-model="agreementChecked">
				我已阅读并同意
				<a href="https://www.dinggebaojian.com/agreement/fwxy.pdf" target="_blank"> 《订个包间服务协议》 </a>
				<a href="https://www.dinggebaojian.com/agreement/bmxy.pdf" target="_blank"> 《订个包间保密协议》 </a>
			</a-checkbox>

			<!-- S footer 其他操作 -->
			<div class="tips_box">
				<span class="tips_2" @click="goRegister(1)">忘记密码</span>
				<!-- <span class="tips_2" style="margin: 0 12px">|</span> -->
				<!-- <span class="tips_2" @click="goRegister(2)">立即注册</span> -->
			</div>
			<!-- E footer 其他操作 -->

			<!-- S modal 登录后选中店铺 -->
			<SelectShop ref="selectShop" />
			<!-- E modal 登录后选中店铺 -->
		</loginRegistration>
	</div>
</template>

<script>
import loginRegistration from '../../components/loginRegistration'
import {getCaptcha, singleLogin, selectShop} from '@/api/Official'
import storage from 'store'
import store from '@/store'
import {ACCESS_TOKEN, USER_INFO} from '@/utils/request'
import {getLoginUrl} from '@/api/user'
import {mapActions} from 'vuex'

// 引入组件
import {SelectShop} from './components'

export default {
	components: {
		loginRegistration,
		SelectShop
	},
	data() {
		return {
			phone: '',
			password: '',
			captcha: '',
			key: '',
			captchaImg: '',
			agreementChecked: false,
			logLoading: false // 登录按钮loading
		}
	},
	mounted() {
		// this.$message.loading({ content: "加载中...", key: "refresh" });
		this.getCaptchaCode()
	},
	methods: {
		...mapActions('user', ['setShopOptions']),
		refreshCaptcha() {
			// this.$message.loading({ content: "更新中...", key: "refresh" });
			this.getCaptchaCode()
		},
		getCaptchaCode() {
			getCaptcha().then(res => {
				if (res.code === 0) {
					// this.$message.destroy();
					const result = res.data || {}
					this.key = result.key || ''
					this.captchaImg = result.img || ''
				}
			})
		},
		goRegister(type) {
			type === 1 && this.$router.push({name: 'Forget'})
			type === 2 && this.$router.push({name: 'Register'})
		},
		goQwLogin() {
			getLoginUrl().then(res => {
				if (res.code === 0) {
					window.location.href = res.data.url
				}
			})
		},

		// 登录函数
		async goLogin() {
			const phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
			if (!this.agreementChecked) {
				this.$message.warning('请同意服务协议和保密协议')
			} else if (!this.phone) {
				this.$message.warning('请输入手机号！')
			} else if (!phoneReg.test(this.phone)) {
				this.$message.warning('请输入正确的手机号！')
			} else if (!this.password) {
				this.$message.warning('请输入密码！')
			} else if (!this.captcha) {
				this.$message.warning('请输入验证码')
			} else {
				this.logLoading = true

				const params = {
					phone: this.phone.trim(),
					password: this.password.trim(),
					captcha: this.captcha.trim(),
					key: this.key
				}

				// 调用登录接口
				const res = await singleLogin(params)
					.catch(err => console.log(err))
					.finally(() => {
						this.logLoading = false
					})
				// 登录接口不通过
				if (res.code / 1 !== 0) {
					this.captcha = ''
					this.getCaptchaCode()
					return false
				}

				store.commit('user/SET_TOKEN', res.data.token)
				storage.set(ACCESS_TOKEN, res.data.token)

				// 赋值数据，等待下一步合并
				let user = res.data.user
				let shop = res.data.shop
				let shop_option = res.data.shop_option

				// 兼容话机
				if (window.android) {
					window.android.saveJsonStr(
						JSON.stringify({
							token: res.data.token,
							userInfo: {shop, ...user}
						})
					)
				}

				// 存储登录的信息数据
				storage.set(USER_INFO, {shop, ...user})
				// 存储登录成功后门店列表
				this.setShopOptions(shop_option)
				// 店铺list只有一个时，直接登录
				if ((res.data.shop_option || []).length === 1) {
					// 提示完成登录，进行页面跳转
					this.$message.success('登录成功').then(this.$router.push({name: 'Index'}))
					// // 选择店铺
					// const shopId = await this.$refs.selectShop.init(
					// 	res.data.shop_option,
					// 	(res.data.user.shop_id || [])[0]
					// )

					// // 调用选择店铺接口
					// const shopRes = await selectShop({ shop_id: shopId }).catch(
					// 	err => console.log(err)
					// )
					// if (shopRes.code / 1 === 0) {
					// 	shop = shopRes.data.shop
					// }
				} else {
					this.$router.push({name: 'StoreList'})
				}
			}
		}
	}
}
</script>

<style lang="less">
.login_form {
	.ant-input {
		height: 44px !important;
		margin-bottom: 26px;
	}

	.ant-input-affix-wrapper {
		height: 44px !important;
		margin-bottom: 26px;
	}
}
</style>

<style scoped lang="less">
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	height: auto;
	-webkit-appearance: none;
}

.slogan_tit_1 {
	font-weight: 500;
	font-size: 30px;
	color: #333333;
	text-align: center;
}

.login_page {
	width: 100%;
	overflow: hidden;
}

.login_form {
	margin-top: 29px;

	.margin_b_26 {
		margin-bottom: 26px;
	}

	.flex {
		.ant-input {
			width: 250px;
			margin-bottom: 0;
		}

		.code {
			width: 190px;
			height: 44px;
			background: #ffffff;
			border: 1px solid #d9d9d9;
			border-radius: 2px;
			text-align: center;
			margin-left: 9px;
			overflow: hidden;

			.captcha_img {
				width: 100%;
				height: 100%;
				margin: 0 auto;
			}
		}
	}

	.submit_btn {
		width: 100%;
		height: 52px;
		background: #e43937;
		border-radius: 2px;
		font-weight: 500;
		font-size: 18px;
		color: #ffffff;
		text-align: center;
		line-height: 52px;
		cursor: pointer;
		margin-bottom: 13px;
	}

	.submit_qywx_btn {
		width: 100%;
		height: 52px;
		border: 1px solid #0082f0;
		border-radius: 2px;
		font-weight: 500;
		font-size: 18px;
		color: #0080f0;
		text-align: center;
		line-height: 52px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;

		.qywx_logo {
			width: 26px;
			height: 27px;
			margin-right: 10px;
		}
	}
}

.tips_box {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 8px;

	.tips_1 {
		color: #666666;
		text-align: center;
		font-weight: 500;
		font-size: 14px;
	}

	.tips_2 {
		color: #e43937;
		text-align: center;
		font-weight: 500;
		font-size: 14px;
		cursor: pointer;
	}
}

::v-deep .ant-checkbox-wrapper {
	margin-top: 21px;
	font-size: 14px;
	font-weight: 500;
}
</style>
